import React, { useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { JohnHancock, Manulife } from '@manulife/mux-logos';
import { useSelector } from 'react-redux';
import { Phone } from '@manulife/mux-cds-icons';
// import JHLogoWhite from 'images/JH_Logo_White.png';
import JHVLogoWhite from 'images/JHV_Logo_White.png';
import DTCPILogoOffWhite from 'images/Manulife_John_Hancock_Wealth_Reverse.png';
// import MFLogoWhite from 'images/MF_Logo_White.png';
import { selectAppSettings } from 'store/reducers/appSettings/appSettings.slice';
import Disclosure from './Disclosures';
import { brandings } from 'constants/brandings';
import useAppids from 'hooks/useAppids';
import {
  ghnwLinks,
  jhLinks,
  mlbLinks,
  rpsContactUs,
  rpsLinks,
  dtcPILinks,
  socialsLinks,
} from './footer.helper';
import { footerStyles } from '../../styles/footer.styles';
import { setWidth } from 'store/reducers/functions/window.slice';
import store from 'store';

const Footer = () => {
  const { branding } = useSelector(selectAppSettings);
  const { isBermudaAppid, isGHNWAppid, isRpsAppid, isDTCPIAppid } = useAppids();
  const styles = footerStyles;

  const getLinks = () => {
    if (isGHNWAppid) return ghnwLinks;
    if (isBermudaAppid) return mlbLinks;
    if (isRpsAppid) return rpsLinks;
    if (isDTCPIAppid) return dtcPILinks;
    return jhLinks;
  };

  return (
    <Grid sx={styles.container}>
      <Grid sx={styles.upperBox}>
        {isRpsAppid ? (
          <Box sx={{ ...styles.linkItem, ...styles.contactUs }}>
            <Phone />
            <a href={rpsContactUs} target="_blank" rel="noreferrer">
              Contact us
            </a>
          </Box>
        ) : null}
        {getLinks().map((item) => {
          return (
            <Box
              key={item.label}
              sx={isDTCPIAppid ? styles.linkItemDTC : styles.linkItem}
            >
              <a href={item.link} target="_blank" rel="noreferrer">
                {item.label}
              </a>
            </Box>
          );
        })}
        {branding === brandings.DTC_PI && (
          <Box sx={styles.socialsContainer}>
            {socialsLinks.map((item) => {
              return (
                <Box key={item.label} sx={styles.socialsImage}>
                  <a href={item.link}>
                    <img src={item.src} alt={item.label} />
                  </a>
                </Box>
              );
            })}
          </Box>
        )}
      </Grid>
      {branding === brandings.DTC_PI ? (
        <DTCLower />
      ) : (
        <LowerDefault branding={branding} />
      )}
    </Grid>
  );
};

const LowerDefault = ({ branding }) => {
  return (
    <Grid sx={footerStyles.lowerBox}>
      <Grid sx={footerStyles.logo}>
        {branding === brandings.MANULIFE || branding === brandings.BERMUDA ? (
          // <img src={MFLogoWhite} alt="Logo" style={styles.logoImg} />
          <Manulife.WHITE alt="Logo" style={footerStyles.logoImg} />
        ) : branding === brandings.VITALITY ? (
          <img src={JHVLogoWhite} alt="Logo" style={footerStyles.logoImg} />
        ) : (
          // <img src={JHLogoWhite} alt="Logo" style={styles.logoImg} />
          <JohnHancock.WHITE alt="Logo" style={footerStyles.logoImg} />
        )}
      </Grid>
      <Disclosure />
    </Grid>
  );
};

const DTCLower = () => {
  useEffect(() => {
    const handleResize = () => {
      store.dispatch(setWidth(window.innerWidth));
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  let d = new Date();
  const year = d.getFullYear();

  return (
    <Grid sx={footerStyles.lowerBoxDTC}>
      <Disclosure />
      <Box sx={footerStyles.logoCopyContainer}>
        <img
          src={DTCPILogoOffWhite}
          alt="Logo"
          style={footerStyles.logoImgDTC}
        />
        <Typography sx={footerStyles.copyText}>
          © {year} by Manulife John Hancock Wealth. All rights reserved.
        </Typography>
      </Box>
    </Grid>
  );
};

export default Footer;
